module.exports = {
      'Prowadnik dolny S60, S100, S120': 'Prowadnik dolny S60, S100, S120',
      'Prowadnik dolny G80, G100': 'Prowadnik dolny G80, G100',
      'Maskownica aluminiowa płaska': 'Maskownica aluminiowa płaska',
      'Maskownica aluminiowa płaska czarna': 'Maskownica aluminiowa płaska czarna',
      'Tuleja dystansowa': 'Tuleja dystansowa',
      'Domykacz do drzwi przesuwnych S120, G100': 'Domykacz do drzwi przesuwnych S120, G100',
      'W60 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W60 System do przesuwnych drzwi w szafach i zabudowach wnęk',
      'W40 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W40 System do przesuwnych drzwi w szafach i zabudowach wnęk',
      'Uchwyt do drzwi przesuwnych 03': 'Uchwyt do drzwi przesuwnych 03',
      'Uchwyt do drzwi przesuwnych 02': 'Uchwyt do drzwi przesuwnych 02',
      'Uchwyt do drzwi przesuwnych 01': 'Uchwyt do drzwi przesuwnych 01',
      'Domykacze do systemów Loft białych': 'Domykacze do systemów Loft białych',
      'Domykacze do systemów Loft czarnych': 'Domykacze do systemów Loft czarnych',
      'System do drzwi przesuwnych Loft I czarny': 'System do drzwi przesuwnych Loft I czarny',
      'System do drzwi przesuwnych Loft T czarny': 'System do drzwi przesuwnych Loft T czarny',
      'System do drzwi przesuwnych Loft V czarny': 'System do drzwi przesuwnych Loft V czarny',
      'System do drzwi przesuwnych Loft U czarny': 'System do drzwi przesuwnych Loft U czarny',
      'System do drzwi przesuwnych Loft I-XL czarny': 'System do drzwi przesuwnych Loft I-XL czarny',
      'System do drzwi przesuwnych Loft KARO czarny': 'System do drzwi przesuwnych Loft KARO czarny',
      'Wózek do systemu F40': 'Wózek do systemu F40',
      'F40 System bez prowadnicy na dodatkową parę drzwi': 'F40 System bez prowadnicy na dodatkową parę drzwi',
      'Wózek do systemu F25': 'Wózek do systemu F25',
      'F25 System bez prowadnicy na dodatkową parę drzwi': 'F25 System bez prowadnicy na dodatkową parę drzwi',
      'G100 System do drzwi przesuwnych szklanych bez prowadnicy': 'G100 System do drzwi przesuwnych szklanych bez prowadnicy',
      'Zestaw G80 bez prowadnicy': 'Zestaw G80 bez prowadnicy',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm': 'Maskownica aluminiowa z zaślepkami bocznymi 45 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm czarna': 'Maskownica aluminiowa z zaślepkami bocznymi 45 mm czarna',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm': 'Maskownica aluminiowa z zaślepkami bocznymi 25 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm czarna': 'Maskownica aluminiowa z zaślepkami bocznymi 25 mm czarna',

      'Uchwyt ⌀ 58 mm': 'Uchwyt ⌀ 58 mm',
      'Uchwyt ⌀ 58 mm czarny': 'Uchwyt ⌀ 58 mm czarny',
      'G80 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G80 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi',
      'G100 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G100 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi',
      'F40 System do drzwi składanych na 1 parę drzwi': 'F40 System do drzwi składanych na 1 parę drzwi',
      'F25 System do drzwi składanych na 1 parę drzwi': 'F25 System do drzwi składanych na 1 parę drzwi',
      'S100 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S100 System do drzwi przesuwnych na 2 skrzydła drzwi',
      'S120 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S120 System do drzwi przesuwnych na 2 skrzydła drzwi',
      'S60 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S60 System do drzwi przesuwnych na 2 skrzydła drzwi',
      'Wózek do systemu S120': 'Wózek do systemu S120',
      'Wózek do systemu S100': 'Wózek do systemu S100',
      'Wózek do systemu S60': 'Wózek do systemu S60',
      
      'Prowadnica aluminiowa B': 'Prowadnica aluminiowa\u00A0B',
      'Prowadnica aluminiowa F': 'Prowadnica aluminiowa\u00A0F',
      'Prowadnica aluminiowa S': 'Prowadnica aluminiowa\u00A0S',
      'Uchwyt maskownicy drewnianej F': 'Uchwyt maskownicy drewnianej\u00A0F',
      'Uchwyt maskownicy drewnianej B': 'Uchwyt maskownicy drewnianej\u00A0B',
      'Uchwyt maskownicy drewnianej S': 'Uchwyt maskownicy drewnianej\u00A0S',
      'Klamra ścienna łącząca prowadnice 25 B': 'Klamra ścienna łącząca prowadnice 25\u00A0B',
      'Klamra ścienna łącząca prowadnice 25 S': 'Klamra ścienna łącząca prowadnice 25\u00A0S',
      'Klamra ścienna łącząca prowadnice 45 S': 'Klamra ścienna łącząca prowadnice 45\u00A0S',
      'Klamra ścienna 25 B': 'Klamra ścienna 25\u00A0B',
      'Klamra ścienna 25 S': 'Klamra ścienna 25\u00A0S',
      'Klamra ścienna 45 S': 'Klamra ścienna 45\u00A0S',

      'S100 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S100 System bez prowadnicy na dodatkowe skrzydło drzwi',
      'S120 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S120 System bez prowadnicy na dodatkowe skrzydło drzwi',
      'S60 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S60 System bez prowadnicy na dodatkowe skrzydło drzwi',
      '2S Mechanizm synchronicznego otwierania i zamykania drzwi': '2S Mechanizm synchronicznego otwierania i zamykania drzwi',
      'Domykacz do drzwi przesuwnych S60, S100': 'Domykacz do drzwi przesuwnych S60, S100',
      'S100 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S100 System do drzwi przesuwnych na 1 skrzydło drzwi',
      'S120 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S120 System do drzwi przesuwnych na 1 skrzydło drzwi',
      'S60 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S60 System do drzwi przesuwnych na 1 skrzydło drzwi',
      'Domykacz do systemu W40': 'Domykacz do systemu W40',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi',
      'L30 System do lekkich drzwi przesuwnych': 'L30 System do lekkich drzwi przesuwnych',
      'L40 System do lekkich drzwi przesuwnych': 'L40 System do lekkich drzwi przesuwnych',
      'L30 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L30 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi',
      'L40 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L40 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi',

      'O firmie': 'O firmie',
      'Produkty': 'Produkty',
      'Regulamin': 'Regulamin',
      'Odstąpienie od umowy': 'Odstąpienie od umowy',
      'Polityka prywatności': 'Polityka prywatności',
      'Koszty dostawy': 'Koszty dostawy',
      'Imprint': 'Imprint',

}
