module.exports = {
      'Prowadnik dolny S60, S100, S120': 'Ondergeleider voor systemen S60, S100, S120',
      'Prowadnik dolny G80, G100': 'Ondergeleider voor systemen G80, G100',
      'Maskownica aluminiowa płaska': 'Plat aluminium latei',
      'Maskownica aluminiowa płaska czarna': 'Plat aluminium latei zwart',
      'Tuleja dystansowa': 'Afstandshuls',
      'Domykacz do drzwi przesuwnych S120, G100': 'Zachte sluitdemper voor S120, G100',
      'W60 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W60 Schuifsysteem voor kast- / kleerkastdeuren',
      'W40 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W40 Schuifsysteem voor kast- / kleerkastdeuren',
      'Uchwyt do drzwi przesuwnych 03': 'Handgreep schuifdeur 03',
      'Uchwyt do drzwi przesuwnych 02': 'Handgreep schuifdeur 02',
      'Uchwyt do drzwi przesuwnych 01': 'Handgreep schuifdeur 01',
      'Domykacze do systemów Loft białych': 'Loft zachte sluitdempers wit',
      'Domykacze do systemów Loft czarnych': 'Loft zachte sluitdempers zwart',
      'System do drzwi przesuwnych Loft I czarny': 'Loft I zwart schuifdeursysteem',
      'System do drzwi przesuwnych Loft T czarny': 'Loft T zwart schuifdeursysteem',
      'System do drzwi przesuwnych Loft V czarny': 'Loft V zwart schuifdeursysteem',
      'System do drzwi przesuwnych Loft U czarny': 'Loft U zwart schuifdeursysteem',
      'System do drzwi przesuwnych Loft I-XL czarny': 'Loft I-XL zwart schuifdeursysteem',
      'System do drzwi przesuwnych Loft KARO czarny': 'Loft KARO zwart schuifdeursysteem',
      'Wózek do systemu F40': 'F40 Roller',
      'F40 System bez prowadnicy na dodatkową parę drzwi': 'F40-vouwdeursysteemkit voor 2 extra panelen',
      'Wózek do systemu F25': 'F25 Roller',
      'F25 System bez prowadnicy na dodatkową parę drzwi': 'F25-vouwdeursysteemkit voor 2 extra panelen',
      'G100 System do drzwi przesuwnych szklanych bez prowadnicy': 'G100 Schuifdeursysteemkit voor extra glazen deuren',
      'Zestaw G80 bez prowadnicy': 'G80 Schuifdeursysteemkit voor extra glazen deuren',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm': 'Geanodiseerde aluminium latei met eindkappen 45 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm czarna': 'Geanodiseerde aluminium latei met eindkappen 45 mm zwart',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm': 'Geanodiseerde aluminium latei met eindkappen 25 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm czarna': 'Geanodiseerde aluminium latei met eindkappen 25 mm zwart',
      
      'Uchwyt ⌀ 58 mm': 'Handgreep ⌀ 58 mm',
      'Uchwyt ⌀ 58 mm czarny': 'Handgreep ⌀ 58 mm zwart',
      'G80 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G80 Schuifdeursysteem voor glazen deuren',
      'G100 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G100 Schuifdeursysteem voor glazen deuren',
      'F40 System do drzwi składanych na 1 parę drzwi': 'F40-vouwdeursysteem voor twee panelen',
      'F25 System do drzwi składanych na 1 parę drzwi': 'F25-vouwdeursysteem voor twee panelen',
      'S100 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S100 Schuifdeursysteem voor twee deuren',
      'S120 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S120 Schuifdeursysteem voor twee deuren',
      'S60 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S60 Schuifdeursysteem voor twee deuren',
      'Wózek do systemu S120': 'S120 Roller',
      'Wózek do systemu S100': 'S100 Roller',
      'Wózek do systemu S60': 'S60 Roller',
      
      'Prowadnica aluminiowa B': 'Aluminium rail\u00A0B',
      'Prowadnica aluminiowa F': 'Aluminium rail\u00A0F',
      'Prowadnica aluminiowa S': 'Aluminium rail\u00A0S',
      'Uchwyt maskownicy drewnianej F': 'Houten lateihaak\u00A0F',
      'Uchwyt maskownicy drewnianej B': 'Houten lateihaak\u00A0B',
      'Uchwyt maskownicy drewnianej S': 'Houten lateihaak\u00A0S',
      'Klamra ścienna łącząca prowadnice 25 B': 'Verbindingsbeugel 25\u00A0B',
      'Klamra ścienna łącząca prowadnice 25 S': 'Verbindingsbeugel 25\u00A0S',
      'Klamra ścienna łącząca prowadnice 45 S': 'Verbindingsbeugel 45\u00A0S',
      'Klamra ścienna 25 B': 'Muurbevestigingssteun 25\u00A0B',
      'Klamra ścienna 25 S': 'Muurbevestigingssteun 25\u00A0S',
      'Klamra ścienna 45 S': 'Muurbevestigingssteun 45\u00A0S',
      
      'S100 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S100 Schuifdeursysteemkit voor extra deuren',
      'S120 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S120 Schuifdeursysteemkit voor extra deuren',
      'S60 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S60 Schuifdeursysteemkit voor extra deuren',
      '2S Mechanizm synchronicznego otwierania i zamykania drzwi': '2S Synchronisatiekit',
      'Domykacz do drzwi przesuwnych S60, S100': 'Zachte sluitdemper voor S60, S100',
      'S100 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S100 Schuifdeursysteem voor een deur',
      'S120 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S120 Schuifdeursysteem voor een deur',
      'S60 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S60 Schuifdeursysteem voor een deur',
      'Domykacz do systemu W40': 'Soft close damper W40',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W40 Sliding system kit for 2 additional door leaves',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W40 Sliding system kit for 1 additional door leaf',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W60 Sliding system kit for 2 additional door leaves',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W60 Sliding system kit for 1 additional door leaf',
      'L30 System do lekkich drzwi przesuwnych': 'L30 System for lightweight sliding doors',
      'L40 System do lekkich drzwi przesuwnych': 'L40 System for lightweight sliding doors',
      'L30 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L30 Sliding system kit for additional door leaf',
      'L40 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L40 Sliding system kit for additional door leaf',

      'O firmie': 'Bedrijf',
      'Produkty': 'Producten',
      'Regulamin': 'Terms and Conditions',
      'Odstąpienie od umowy': 'Cancellation',
      'Polityka prywatności': 'Data Protection',
      'Koszty dostawy': 'Verzending en betaling',
      'Imprint': 'Imprint',

}
