module.exports = {
      'Prowadnik dolny S60, S100, S120': 'Lower guide for S60, S100, S120 systems',
      'Prowadnik dolny G80, G100': 'Lower guide for G80 and G100 systems',
      'Maskownica aluminiowa płaska': 'Flat aluminium fascia',
      'Maskownica aluminiowa płaska czarna': 'Flat aluminium fascia black',
      'Tuleja dystansowa': 'Spacer',
      'Domykacz do drzwi przesuwnych S120, G100': 'Soft close damper S120, G100',
      'W60 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W60 Sliding system for closet / wardrobe doors',
      'W40 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W40 Sliding system for closet / wardrobe doors',
      'Uchwyt do drzwi przesuwnych 03': 'Door handle 03',
      'Uchwyt do drzwi przesuwnych 02': 'Door handle 02',
      'Uchwyt do drzwi przesuwnych 01': 'Door handle 01',
      'Domykacze do systemów Loft białych': 'Loft soft close dampers white',
      'Domykacze do systemów Loft czarnych': 'Loft soft close dampers black',
      'System do drzwi przesuwnych Loft I czarny': 'Loft sliding system I black',
      'System do drzwi przesuwnych Loft T czarny': 'Loft sliding system T black',
      'System do drzwi przesuwnych Loft V czarny': 'Loft sliding system V black',
      'System do drzwi przesuwnych Loft U czarny': 'Loft sliding system U black',
      'System do drzwi przesuwnych Loft I-XL czarny': 'Loft sliding system I-XL black',
      'System do drzwi przesuwnych Loft KARO czarny': 'Loft sliding system KARO black',
      'Wózek do systemu F40': 'F40 Roller',
      'F40 System bez prowadnicy na dodatkową parę drzwi': 'F40 Folding door kit for 2 additional panels',
      'Wózek do systemu F25': 'F25 Roller',
      'F25 System bez prowadnicy na dodatkową parę drzwi': 'F25 Folding door kit for 2 additional panels',
      'G100 System do drzwi przesuwnych szklanych bez prowadnicy': 'G100 Sliding door kit for additional glass door',
      'Zestaw G80 bez prowadnicy': 'G80 Sliding door kit for additional glass door',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm': 'Anodized aluminium fascia with end caps 45 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm czarna': 'Anodized aluminium fascia with end caps 45 mm black',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm': 'Anodized aluminium fascia with end caps 25 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm czarna': 'Anodized aluminium fascia with end caps 25 mm black',
      
      'Uchwyt ⌀ 58 mm': 'Handle ⌀ 58 mm',
      'Uchwyt ⌀ 58 mm czarny': 'Handle ⌀ 58 mm black',
      'G80 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G80 Sliding door system for glass door',
      'G100 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G100 Sliding door system for glass door',
      'F40 System do drzwi składanych na 1 parę drzwi': 'F40 Folding door system for 2 panels',
      'F25 System do drzwi składanych na 1 parę drzwi': 'F25 Folding door system for 2 panels',
      'S100 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S100 Sliding door system for two doors',
      'S120 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S120 Sliding door system for two doors',
      'S60 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S60 Sliding door system for two doors',
      'Wózek do systemu S120': 'S120 Roller',
      'Wózek do systemu S100': 'S100 Roller',
      'Wózek do systemu S60': 'S60 Roller',
      
      'Uchwyt maskownicy drewnianej do systemu G80': 'Uchwyt maskownicy drewnianej do systemu G80',
      'Uchwyt maskownicy drewnianej do systemów S60, S100, S120, G100': 'Uchwyt maskownicy drewnianej do systemów S60, S100, S120, G100',

      'Prowadnica aluminiowa B': 'Aluminium track\u00A0B',
      'Prowadnica aluminiowa F': 'Aluminium track\u00A0F',
      'Prowadnica aluminiowa S': 'Aluminium track\u00A0S',
      'Uchwyt maskownicy drewnianej F': 'Wooden fascia bracket\u00A0F',
      'Uchwyt maskownicy drewnianej B': 'Wooden fascia bracket\u00A0B',
      'Uchwyt maskownicy drewnianej S': 'Wooden fascia bracket\u00A0S',
      'Klamra ścienna łącząca prowadnice 25 B': 'Connecting bracket 25\u00A0B',
      'Klamra ścienna łącząca prowadnice 25 S': 'Connecting bracket 25\u00A0S',
      'Klamra ścienna łącząca prowadnice 45 S': 'Connecting bracket 45\u00A0S',
      'Klamra ścienna 25 B': 'Wall fixing bracket 25\u00A0B',
      'Klamra ścienna 25 S': 'Wall fixing bracket 25\u00A0S',
      'Klamra ścienna 45 S': 'Wall fixing bracket 45\u00A0S',

      'S100 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S100 Sliding system kit for additional door leaf',
      'S120 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S120 Sliding system kit for additional door leaf',
      'S60 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S60 Sliding system kit for additional door leaf',
      '2S Mechanizm synchronicznego otwierania i zamykania drzwi': '2S Synchronization kit',
      'Domykacz do drzwi przesuwnych S60, S100': 'Soft close damper S60, S100',
      'S100 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S100 Sliding door system for one door',
      'S120 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S120 Sliding door system for one door',
      'S60 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S60 Sliding door system for one door',
      'Domykacz do systemu W40': 'Soft close damper W40',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W40 Sliding system kit for 2 additional door leaves',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W40 Sliding system kit for 1 additional door leaf',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W60 Sliding system kit for 2 additional door leaves',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W60 Sliding system kit for 1 additional door leaf',
      'L30 System do lekkich drzwi przesuwnych': 'L30 System for lightweight sliding doors',
      'L40 System do lekkich drzwi przesuwnych': 'L40 System for lightweight sliding doors',
      'L30 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L30 Sliding system kit for additional door leaf',
      'L40 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L40 Sliding system kit for additional door leaf',

      'O firmie': 'Company',
      'Produkty': 'Products',
      'Regulamin': 'Terms and Conditions',
      'Odstąpienie od umowy': 'Cancellation',
      'Polityka prywatności': 'Data Protection',
      'Koszty dostawy': 'Shipping and Payment',
      'Imprint': 'Imprint',

}
