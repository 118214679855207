// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/en/products/pura': '/pl/produkty/pura',
      '/en/products/connecting-bracket-25-b': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-b',
      '/en/products/wooden-fascia-bracket-b': '/pl/produkty/uchwyt-maskownicy-drewnianej-b',
      '/en/products/wall-fixing-bracket-25-b': '/pl/produkty/klamra-scienna-25-b',
      '/en/products/aluminium-track-b': '/pl/produkty/prowadnica-aluminiowa-b',
      '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm-black': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm-czarna',
      '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm-black': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm-czarna',
      '/en/products/flat-aluminium-fascia-black': '/pl/produkty/maskownica-aluminiowa-plaska-czarna',
      '/en/products/handle-58-mm-black': '/pl/produkty/uchwyt-58-mm-czarny',
      '/en/products/lower-guide-for-g80-and-g100-systems': '/pl/produkty/prowadnik-dolny-g80-g100',
      '/en/products/soft-close-damper-s120-g100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s120-g100',
      '/en/products/soft-close-damper-s60-s100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s60-s100',
      '/en/products/loft-sliding-system-t-black': '/pl/produkty/system-do-drzwi-przesuwnych-loft-t-czarny',
      '/en/products/w40-sliding-system-kit-for-2-additional-door-leaves': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/en/products/w40-sliding-system-kit-for-1-additional-door-leaf': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/en/products/l30-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/en/products/l40-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/en/products/soft-close-damper-w40': '/pl/produkty/domykacz-do-systemu-w40',
      '/en/products/w60-sliding-system-kit-for-2-additional-door-leaves': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/en/products/w60-sliding-system-kit-for-1-additional-door-leaf': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/en/products/l30-system-for-lightweight-sliding-doors': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych',
      '/en/products/l40-system-for-lightweight-sliding-doors': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych',
      '/en/products/loft-sliding-system-karo-black': '/pl/produkty/system-do-drzwi-przesuwnych-loft-karo-czarny',
      '/en/products/loft-sliding-system-u-black': '/pl/produkty/system-do-drzwi-przesuwnych-loft-u-czarny',
      '/en/products/lower-guide-for-s60-s100-s120-systems': '/pl/produkty/prowadnik-dolny-s60-s100-s120',
      '/en/products/flat-aluminium-fascia': '/pl/produkty/maskownica-aluminiowa-plaska',
      '/en/products/spacer': '/pl/produkty/tuleja-dystansowa',
      '/en/products/w60-sliding-system-for-closet-wardrobe-doors': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/en/products/w40-sliding-system-for-closet-wardrobe-doors': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/en/products/door-handle-03': '/pl/produkty/uchwyt-do-drzwi-przesuwnych-03',
      '/en/products/loft-soft-close-dampers-white': '/pl/produkty/domykacze-do-systemow-loft-bialych',
      '/en/products/loft-soft-close-dampers-black': '/pl/produkty/domykacze-do-systemow-loft-czarnych',
      '/en/products/loft-sliding-system-v-black': '/pl/produkty/system-do-drzwi-przesuwnych-loft-v-czarny',
      '/en/products/f40-roller': '/pl/produkty/wozek-do-systemu-f40',
      '/en/products/f40-folding-door-kit-for-2-additional-panels': '/pl/produkty/f40-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/en/products/wooden-fascia-bracket-f': '/pl/produkty/uchwyt-maskownicy-drewnianej-f',
      '/en/products/f25-roller': '/pl/produkty/wozek-do-systemu-f25',
      '/en/products/aluminium-track-f': '/pl/produkty/prowadnica-aluminiowa-f',
      '/en/products/f25-folding-door-kit-for-2-additional-panels': '/pl/produkty/f25-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/en/products/g100-sliding-door-kit-for-additional-glass-door': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-bez-prowadnicy',
      '/en/products/g80-sliding-door-kit-for-additional-glass-door': '/pl/produkty/zestaw-g80-bez-prowadnicy',
      '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm',
      '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm',
      '/en/products/aluminium-track-s': '/pl/produkty/prowadnica-aluminiowa-s',
      '/en/products/handle-58-mm': '/pl/produkty/uchwyt-58-mm',
      '/en/products/loft-sliding-system-i-xl-black': '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-xl-czarny',
      '/en/products/loft-sliding-system-i-black': '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-czarny',
      '/en/products/g80-sliding-door-system-for-glass-door': '/pl/produkty/g80-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/en/products/g100-sliding-door-system-for-glass-door': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/en/products/f40-folding-door-system-for-2-panels': '/pl/produkty/f40-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/en/products/f25-folding-door-system-for-2-panels': '/pl/produkty/f25-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/en/products/s100-sliding-door-system-for-two-doors': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/en/products/s120-sliding-door-system-for-two-doors': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/en/products/s60-sliding-door-system-for-two-doors': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/en/products/s120-roller': '/pl/produkty/wozek-do-systemu-s120',
      '/en/products/s100-roller': '/pl/produkty/wozek-do-systemu-s100',
      '/en/products/s60-roller': '/pl/produkty/wozek-do-systemu-s60',
      '/en/products/wooden-fascia-bracket-s': '/pl/produkty/uchwyt-maskownicy-drewnianej-s',
      '/en/products/connecting-bracket-45-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-45-s',
      '/en/products/connecting-bracket-25-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-s',
      '/en/products/wall-fixing-bracket-45-s': '/pl/produkty/klamra-scienna-45-s',
      '/en/products/wall-fixing-bracket-25-s': '/pl/produkty/klamra-scienna-25-s',
      '/en/products/s100-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/s100-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/en/products/s120-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/s120-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/en/products/s60-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/s60-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/en/products/2s-synchronization-kit': '/pl/produkty/2s-mechanizm-synchronicznego-otwierania-i-zamykania-drzwi',
      '/en/products/s100-sliding-door-system-for-one-door': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/en/products/s120-sliding-door-system-for-one-door': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/en/products/s60-sliding-door-system-for-one-door': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/en/imprint': '/pl/imprint',
      '/en/shipping-and-payment': '/pl/koszty-dostawy',
      '/en/data-protection': '/pl/polityka-prywatnosci',
      '/en/cancellation': '/pl/odstapienie-od-umowy',
      '/en/company': '/pl/o-firmie',
      '/en/terms-and-conditions': '/pl/regulamin',
      '/en/products': '/pl/produkty',
}
