// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/pl/produkty/pura': '/pl/produkty/pura',
      '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-b': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-b',
      '/pl/produkty/uchwyt-maskownicy-drewnianej-b': '/pl/produkty/uchwyt-maskownicy-drewnianej-b',
      '/pl/produkty/klamra-scienna-25-b': '/pl/produkty/klamra-scienna-25-b',
      '/pl/produkty/prowadnica-aluminiowa-b': '/pl/produkty/prowadnica-aluminiowa-b',
      '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm-czarna': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm-czarna',
      '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm-czarna': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm-czarna',
      '/pl/produkty/maskownica-aluminiowa-plaska-czarna': '/pl/produkty/maskownica-aluminiowa-plaska-czarna',
      '/pl/produkty/uchwyt-58-mm-czarny': '/pl/produkty/uchwyt-58-mm-czarny',
      '/pl/produkty/prowadnik-dolny-g80-g100': '/pl/produkty/prowadnik-dolny-g80-g100',
      '/pl/produkty/domykacz-do-drzwi-przesuwnych-s120-g100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s120-g100',
      '/pl/produkty/domykacz-do-drzwi-przesuwnych-s60-s100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s60-s100',
      '/pl/produkty/system-do-drzwi-przesuwnych-loft-t-czarny': '/pl/produkty/system-do-drzwi-przesuwnych-loft-t-czarny',
      '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/domykacz-do-systemu-w40': '/pl/produkty/domykacz-do-systemu-w40',
      '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych',
      '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych',
      '/pl/produkty/system-do-drzwi-przesuwnych-loft-karo-czarny': '/pl/produkty/system-do-drzwi-przesuwnych-loft-karo-czarny',
      '/pl/produkty/system-do-drzwi-przesuwnych-loft-u-czarny': '/pl/produkty/system-do-drzwi-przesuwnych-loft-u-czarny',
      '/pl/produkty/prowadnik-dolny-s60-s100-s120': '/pl/produkty/prowadnik-dolny-s60-s100-s120',
      '/pl/produkty/maskownica-aluminiowa-plaska': '/pl/produkty/maskownica-aluminiowa-plaska',
      '/pl/produkty/tuleja-dystansowa': '/pl/produkty/tuleja-dystansowa',
      '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/pl/produkty/uchwyt-do-drzwi-przesuwnych-03': '/pl/produkty/uchwyt-do-drzwi-przesuwnych-03',
      '/pl/produkty/domykacze-do-systemow-loft-bialych': '/pl/produkty/domykacze-do-systemow-loft-bialych',
      '/pl/produkty/domykacze-do-systemow-loft-czarnych': '/pl/produkty/domykacze-do-systemow-loft-czarnych',
      '/pl/produkty/system-do-drzwi-przesuwnych-loft-v-czarny': '/pl/produkty/system-do-drzwi-przesuwnych-loft-v-czarny',
      '/pl/produkty/wozek-do-systemu-f40': '/pl/produkty/wozek-do-systemu-f40',
      '/pl/produkty/f40-system-bez-prowadnicy-na-dodatkowa-pare-drzwi': '/pl/produkty/f40-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/pl/produkty/uchwyt-maskownicy-drewnianej-f': '/pl/produkty/uchwyt-maskownicy-drewnianej-f',
      '/pl/produkty/wozek-do-systemu-f25': '/pl/produkty/wozek-do-systemu-f25',
      '/pl/produkty/prowadnica-aluminiowa-f': '/pl/produkty/prowadnica-aluminiowa-f',
      '/pl/produkty/f25-system-bez-prowadnicy-na-dodatkowa-pare-drzwi': '/pl/produkty/f25-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-bez-prowadnicy': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-bez-prowadnicy',
      '/pl/produkty/zestaw-g80-bez-prowadnicy': '/pl/produkty/zestaw-g80-bez-prowadnicy',
      '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm',
      '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm',
      '/pl/produkty/prowadnica-aluminiowa-s': '/pl/produkty/prowadnica-aluminiowa-s',
      '/pl/produkty/uchwyt-58-mm': '/pl/produkty/uchwyt-58-mm',
      '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-xl-czarny': '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-xl-czarny',
      '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-czarny': '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-czarny',
      '/pl/produkty/g80-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi': '/pl/produkty/g80-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/pl/produkty/f40-system-do-drzwi-skladanych-na-1-pare-drzwi': '/pl/produkty/f40-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/pl/produkty/f25-system-do-drzwi-skladanych-na-1-pare-drzwi': '/pl/produkty/f25-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/pl/produkty/wozek-do-systemu-s120': '/pl/produkty/wozek-do-systemu-s120',
      '/pl/produkty/wozek-do-systemu-s100': '/pl/produkty/wozek-do-systemu-s100',
      '/pl/produkty/wozek-do-systemu-s60': '/pl/produkty/wozek-do-systemu-s60',
      '/pl/produkty/uchwyt-maskownicy-drewnianej-s': '/pl/produkty/uchwyt-maskownicy-drewnianej-s',
      '/pl/produkty/klamra-scienna-laczaca-prowadnice-45-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-45-s',
      '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-s',
      '/pl/produkty/klamra-scienna-45-s': '/pl/produkty/klamra-scienna-45-s',
      '/pl/produkty/klamra-scienna-25-s': '/pl/produkty/klamra-scienna-25-s',
      '/pl/produkty/s100-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi': '/pl/produkty/s100-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/s120-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi': '/pl/produkty/s120-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/s60-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi': '/pl/produkty/s60-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/pl/produkty/2s-mechanizm-synchronicznego-otwierania-i-zamykania-drzwi': '/pl/produkty/2s-mechanizm-synchronicznego-otwierania-i-zamykania-drzwi',
      '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/pl/imprint': '/pl/imprint',
      '/pl/koszty-dostawy': '/pl/koszty-dostawy',
      '/pl/polityka-prywatnosci': '/pl/polityka-prywatnosci',
      '/pl/odstapienie-od-umowy': '/pl/odstapienie-od-umowy',
      '/pl/o-firmie': '/pl/o-firmie',
      '/pl/regulamin': '/pl/regulamin',
      '/pl/produkty': '/pl/produkty',
}
