
const S60_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 16–40 mm | \n | Max. door weight | 60 kg | \n | No. of door leaves | 1 | \n'
const S60_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit weight (without the track)| 400 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'
const S100_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 16–40 mm | \n | Max. door weight | 100 kg | \n | No. of door leaves | 1 | \n'
const S100_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit weight (without the track)| 400 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'
const S120_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 22–45 mm | \n | Max. door weight | 120 kg | \n | No. of door leaves | 1 | \n'
const S120_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit weight (without the track)| 400 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'

const _2S60_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 16–40 mm | \n | Max. door weight | 60 kg | \n | No. of door leaves | 2 | \n'
const _2S60_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Single kit weight (without the track)| 400 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'
const _2S100_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 16–40 mm | \n | Max. door weight | 100 kg | \n | No. of door leaves | 2 | \n'
const _2S100_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Single kit weight (without the track)| 400 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'
const _2S120_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 22–45 mm | \n | Max. door weight | 120 kg | \n | No. of door leaves | 2 | \n'
const _2S120_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Single kit weight (without the track)| 400 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'

const G100_table_1 = '* Information about glass\n \n |||\n|---|---|\n| Material | Safety tempered glass (ESG) or safety laminated glass (VSG) | \n | Thickness | 8, 10 or 12 mm | \n | Max. door weight | 100 kg | \n | No. of door leaves | 1 | \n'
const G100_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit Weight (without the track)| 600 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'
const G80_table_1 = '* Information about glass\n \n |||\n|---|---|\n| Material | Safety tempered glass (ESG) or safety laminated glass (VSG) | \n | Thickness | 8, 10 or 12 mm | \n | Max. door weight | 80 kg | \n | No. of door leaves | 1 | \n'
const G80_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit Weight (without the track)| 600 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting|	Ceiling / Wall (optional)|  \n'

const LFTB_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 35–45 mm | \n | Max. door weight | 100 kg | \n | No. of door leaves | 1 | \n'

const F25_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 16–40 mm | \n | Max. door weight (both panels) | 25 kg | \n | No. of door panels | 2 | \n'
const F25_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit weight (without the track)| 300 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting| Ceiling|  \n'
const F40_table_1 = '* Information about doors\n \n |||\n|---|---|\n| Material | Wood, plywood, chipboard, MDF and honeycomb panel | \n | Thickness | 16–40 mm | \n | Max. door weight (both panels) | 40 kg | \n | No. of door panels | 2 | \n'
const F40_table_2 = '* Technical data\n \n |||\n|---|---|\n| Kit material|Polyacetal (POM), steel, aluminium| \n |Material of the track|Aluminium| \n |Kit weight (without the track)| 300 g | \n |Weight of the track (1 m)| 600 g| \n |Mounting| Ceiling|  \n'


module.exports = {
    'ContactBarInfo1': 'Do you have questions? Give us a call:',
    'ContactBarInfo2': '(Mon-Fri 8-16) or email us:',
    'ContactBarInfo3': 'Fast contact:',
    'ContactBarInfo4': '(Mon-Fri 8-16). Email:',

    'Dodaj do koszyka': 'Add to cart',
    'Koszyk': 'Cart',
    'Twój koszyk jest pusty.': 'Your cart is empty.',
    'Wartość koszyka': 'Total',
    'Przejdź do kasy': 'Proceed to checkout',
    'Znaleziono': 'Found',
    'Szukaj produktów': 'Search for products',
    'Ceny: od': 'Sort by price:',
    'od ': 'from ',
    'najwyższej': 'high to low',
    'najniższej': 'low to high',
    'loading': 'loading',

    'Strona Główna': 'Home',
    'Produkty': 'Products',
    'Seria F': 'F Series',
    'Seria G': 'G Series',
    'Seria L': 'L Series',
    'Seria Loft': 'Loft Series',
    'Seria S': 'S Series',
    'Seria W': 'W Series',

    'Zawartość zestawu': 'Kit contents',
    'Główne cechy': 'Main features',
    'Gwarancja, zwroty, dostawa': 'Warranty, returns, delivery',
    'Przydatne informacje': 'Useful information',
    'Rysunki techniczne': 'Technical drawings',
    'Do pobrania': 'Downloads',
    'Może Ci się spodobać': 'You may also like',

    'warranty_info': '5-year warranty on all components of the kit.',
    'return_info': 'You have the right to cancel your purchase within 30 days.',
    'shipping_info_0': 'Shipping cost is ',
    'shipping_info_1': 'Shipping cost is ​4.70 €',
    'shipping_info_2': 'Shipping takes 3-9 days',
    'shipping_info_2B': 'Shipping takes 2-7 days',
    'shipping_info_3': '',

    //Menu
    'Systemy do drzwi przejściowych': 'Systems for partition doors',
    'Systemy do drzwi w szafach': 'Systems for closet doors',
    'Seria S – przesuwne': 'S Series – sliding',
    'Seria G – przesuwne do szkła': 'G Series – sliding glass',
    'Seria Loft – przesuwne': 'Loft Series – sliding',
    'Seria F – składane': 'F Series  – folding',
    'Seria W – przesuwne': 'W Series  – sliding',
    'Seria L – przesuwne lekkie': 'L Series – light sliding',
    'Akcesoria': "Accessories",
    'Akcesoria G100': "G100 Accessories",
    'Akcesoria Loft': "Loft Accessories",
    'Systemy Loft': "Loft Systems",

    // Konfigurator 
    'Wariant': 'Variant',
    'Produkt': 'Product',
    'Prowadnica': 'Track',
    'Długość': 'Length',
    'Mocowanie': 'Mounting',
    'Do stropu': 'Ceiling',
    'Do ściany': 'Wall',
    'Grubość drzwi': 'Door thickness',
    'Maskownica aluminiowa': 'Aluminium fascia',
    'Maskownica aluminiowa płaska': 'Flat aluminium fascia',
    'Maskownica płaska': 'Flat fascia',
    'Maskownica alu.': 'Aluminium fascia',
    'Uchwyt maskownicy drewnianej': 'Wooden fascia bracket',
    'Uchwyt maskow. drewn.': 'Wooden fascia bracket',
    'Tak': 'Yes',
    'Nie': 'No',
    'Srebrna': 'Silver',
    'Czarna': 'Black',
    'Miękkie domykanie': 'Soft close',
    '1 strona': '1 end',
    '2 strony': '2 ends',
    "Otwieranie synchroniczne": 'Synchronization kit',
    "Tuleja dystansowa": 'Spacer',

    // Zawartosc zestawu 
    'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach kulkowych': 'Roller made of highly resistant material - polyoxymethylene',
    'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach igiełkowych': 'Roller made of highly resistant material - polyoxymethylene',
    'Prowadnica aluminiowa z otworami pod wkręty Ø 4 mm': 'Aluminium track with holes Ø4 mm. Can be cut to size',
    'Stoper blokujący drzwi w krańcowych położeniach': 'End position stopper',
    'Stoper odbijający': 'End position buffer',
    'Mocowanie szkła': 'Glass mounting',
    'Wkładki elastomerowe do zacisków, dla różnej grubość szkła': 'Elastomer clamps inserts, for different glass thickness',
    'Samoprzylepne uchwyty do drzwi z bezbarwnego tworzywa': 'Self-adhesive handles made of transparent plastic',
    'Prowadnik dolny wykonany z bezbarwnego tworzywa': 'Transparent lower guide',
    'Klucz imbusowy': 'Hex key',
    'Komplet kluczy imbusowych': 'Set of hex keys',
    'Obsada czopu górnego': 'Top Trunnion',
    'Czop górny': 'Top Pin',
    'Czop dolny': 'Bottom Pin',
    'Obsada czopu dolnego': 'Bottom Trunnion',
    'Zawias': 'Hinge',
    'Wkręty montażowe': 'Mounting screws',
    'Wkręty mocujące': 'Mounting screws',
    'Domykacz': 'Soft close damper',
    'Zaczep do drzwi drewnianych': 'Hook for wooden door',
    'Zaczep do drzwi szklanych': 'Hook for glass door',
    'Zaczep uniwersalny': 'Multi-purpose hook',
    'Podkładka': 'Support',
    'Domykacz wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka. Do drzwi o wadze do 80 kg': 'Soft close damper. Damper smoothly and slowly closes the door. For doors up to 80 kg',
    'Uchwyt maskownicy': 'Wooden fascia bracket',
    'Klamra ścienna 45': 'Wall fixing bracket 45',
    'Klamra ścienna 25': 'Wall fixing bracket 25',
    'Maskownica aluminiowa z zaślepkami bocznymi i szczotką przeciwkurzową': 'Anodized aluminium fascia, set of end caps and brush seal',
    'Linka stalowa – 8 m': 'Cable – 8 m',
    'Stoper z rolką': 'Assembled pulleys',
    'Wspornik montażowy ze sprężyną': 'Fixing plate with spring',
    'Łącznik': 'Driving angle plate',
    'Prowadnica stalowa': 'Steel track',
    'Wózek': 'Roller',
    'Stoper': 'Stopper',
    'Mocowanie prowadnicy': 'Wall mounting',
    'Ogranicznik górny': 'Upper stopper',
    'Prowadnik dolny': 'Lower guide',
    'Komplet zaślepek bocznych': 'Set of end caps',
    'Szczotka przeciwkurzowa': 'Dust brush',

    //Home
    'Zobacz więcej': 'See more',
    'System przesuwny': 'Sliding door system',
    'Systemy przesuwne': 'Sliding door systems',
    'Systemy przesuwne do szkła': 'Glass door solutions',
    'Systemy do lekkich drzwi przesuwnych': 'Systems for light sliding doors',
    'Systemy składane': 'Folding door systems',
    'Systemy do drzwi przesuwnych \n w szafach i zabudowach wnęk': 'Sliding door systems for wardrobes',
    'hero-copy-pura': 'Discover modernity and elegance with our sliding door system with a completely hidden track. Designed to fit perfectly into minimalist interiors, it offers smooth and quiet operation. An innovative design that makes the guide invisible, ensuring a clean and aesthetic appearance. Thanks to advanced technology, the doors slide almost silently. Made of high-quality materials, it guarantees longevity and reliability.',
    'hero-copy-s-series': 'Three systems for sliding doors: up to 60 kg, 100 kg and 120 kg. Each of them can be fastened to the ceiling or to the wall with the use of optional wall fixing brackets, equipped with wooden fascia brackets, self-closing dampers, and in the case of systems with 2 door leaves, a set enabling synchronous opening. In addition, System S120 can be equipped with an aluminium fascia, ideally suited to modern interiors.',
    'hero-copy-g-series': 'We offer two systems for sliding glass doors. The G80 system (load of door leaf up to 80 kg) can be mounted to the ceiling or optionally to the wall. The G100 (load of door leaf up to 100 kg) system does not require drilling holes in the glass, it can be equipped with an aluminum fascia, self-closing dampers. It can also be mounted to the ceiling or optionally to the walls.',
    'hero-copy-loft-series': 'A series of systems resembling classic sliding door systems. They fit in interiors with a distinctive character referring to the style of a loft or a rustic atmosphere. The systems come with several variants of trolleys and in two colors, black and white. They can be equipped with a set of self-closers as well as door handles in a matching style.',

    //Opisy 
    'S60_description': '* Max. door weight 60 kg\n* Length of the rail from 100 to 200 cm\n* Mounting to the ceiling or on the wall\n* Trolleys are equipped with needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 60 kg',
    'S60_good_to_know': `${S60_table_1} ${S60_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted)\n* Doors not included in delivery`,
    'S100_description': '* Max. door weight 100 kg\n* Length of the rail from 100 to 200 cm\n* Mounting to the ceiling or on the wall\n* Trolleys are equipped with high strength needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 100 kg',
    'S100_good_to_know': `${S100_table_1} ${S100_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted)\n* Doors not included in delivery`,
    'S120_description': '* Max. door weight 120 kg\n* Length of the rail from 100 to 200 cm\n* Mounting to the ceiling or on the wall\n* Trolleys are equipped with the highest strength ball bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 120 kg\n * Nylon wheels ensure quiet operation',
    'S120_good_to_know': `${S120_table_1} ${S120_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted)\n* Doors not included in delivery`,
    'S60_kit_description': '* Max. door weight 60 kg\n* Trolleys are equipped with needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 60 kg',
    'S100_kit_description': '* Max. door weight 100 kg\n* Trolleys are equipped with high strength needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 100 kg',
    'S120_kit_description': '* Max. door weight 120 kg\n* Trolleys are equipped with the highest strength ball bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 120 kg\n * Nylon wheels ensure quiet operation',
    '2S60_description': '* Max. door weight 60 kg\n* Length of the rail from 140 to 200 cm\n* Mounting to the ceiling or on the wall\n* Trolleys are equipped with needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 60 kg',
    '2S100_description': '* Max. door weight 100 kg\n* Length of the rail from 140 to 200 cm\n* Mounting to the ceiling or on the wall\n* Trolleys are equipped with high strength needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 100 kg',
    '2S120_description': '* Max. door weight 120 kg\n* Length of the rail from 140 to 200 cm\n* Mounting to the ceiling or on the wall\n* Trolleys are equipped with the highest strength ball bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 120 kg\n * Nylon wheels ensure quiet operation',
    '2S60_good_to_know': `${_2S60_table_1} ${_2S60_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted), gear enabling synchronous opening and closing of 2 door leaves in opposite directions\n* Doors not included in delivery`,
    '2S100_good_to_know': `${_2S100_table_1} ${_2S100_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted), gear enabling synchronous opening and closing of 2 door leaves in opposite directions\n* Doors not included in delivery`,
    '2S120_good_to_know': `${_2S120_table_1} ${_2S120_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted), gear enabling synchronous opening and closing of 2 door leaves in opposite directions\n* Doors not included in delivery`,
    'RO-S60_description': '* Max. door weight 60 kg (with 2 rollers)',
    'RO-S100_description': '* Max. door weight 100 kg (with 2 rollers)',
    'RO-S120_description': '* Max. door weight 120 kg (with 2 rollers)',

    'LFT-B_description': '* Max. door weight 100 kg\n * Mounting on the wall\n * Length of the rail 200 cm\n * Black matte finish',
    'LFT-B_good_to_know': `${LFTB_table_1} * Doors not included in delivery`,
    'G100_description': '* Max. door weight 100 kg\n * Length of the rail from 100 to 200 cm\n * Mounting to the ceiling or on the wall\n * It does not require drilling holes in the glass\n * Trolleys are equipped with the highest strength ball bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 100 kg\n * Nylon wheels ensure quiet operation',
    'G100_kit_description': '* Max. door weight 100 kg\n * It does not require drilling holes in the glass\n * Trolleys are equipped with the highest strength ball bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 100 kg\n * Nylon wheels ensure quiet operation',
    'G100_good_to_know': `* The Tycho G100 system is intended for sliding glass doors. Glass should be ordered in a glass factory in a right size. Glass does not require drilling holes to fasten the system.\n ${G100_table_1} ${G100_table_2} * Options available in the configurator: wall fixing brackets, soft closing damper, wooden fascia brackets, aluminium fascia with end caps (possible when wall mounted)\n * Doors not included in delivery`,
    'G80_description': '* Max. door weight 80 kg\n * Length of the rail from 100 to 200 cm\n * Mounting to the ceiling or on the wall\n * Attention. Drilling holes in the glass is required\n * Trolleys are equipped with high strength needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 80 kg',
    'G80_kit_description': '* Max. door weight 80 kg\n * Attention. Drilling holes in the glass is required\n * Trolleys are equipped with high strength needle roller bearings that ensure trouble-free operation of up to 100,000 cycles for opening and closing doors weighing up to 80 kg',
    'G80_good_to_know': `* The Tycho G80 system is intended for sliding glass doors. Glass should be ordered in a glass factory in a right size and with drilling to fasten the system.\n ${G80_table_1} ${G80_table_2} * Options available in the configurator: wall fixing brackets, wooden fascia brackets\n * Doors not included in delivery`,
    'LFT-SCD_description': '* Dampers smoothly and slowly close the door\n * For the Loft sliding systems\n * Set of 2 pcs.',

    'DS40_description': '* Damper smoothly and slowly closes the door\n * Mounted inside of the rail\n * Can be mounted at both ends of the rail\n * For doors up to 45 kg\n * For sliding systems W40',
    'DS40_good_to_know': '* **Kit contents:** \n * Damper ×1\n * Damper mount ×2\n * Door hook ×1\n * Mounting screws ×1 set',

    'SCD-SG_description': '* Damper smoothly and slowly closes the door\n * Can be mounted at both ends of the rail\n * Mounted inside of the rail\n * 3-step adjustment of closing force (≤40 kg, 41÷60 kg, 61÷80 kg)\n * For doors up to 80 kg\n * Can be applied to an existing Tycho system installation\n * For sliding systems S120, G100',
    'SCD-S_description': '* Damper smoothly and slowly closes the door\n * Can be mounted at both ends of the rail\n * Mounted inside of the rail\n * 3-step adjustment of closing force (≤40 kg, 41÷60 kg, 61÷80 kg)\n * For doors up to 80 kg\n * Can be applied to an existing Tycho system installation\n * For sliding systems S60, S100',
    '2S_description': '* Gear enabling synchronous opening and closing of 2 door leaves in opposite directions\n * For sliding door systems S60, S100, S120\n * Max. door weight 60 kg\n * For wooden doors\n * Max. opening width 4 m',

    'L30_description': '* Smooth and silent ball bearing rollers\n * Track length from 100 to 200 cm\n * Max. door weight 30 kG\n * Easy assembly without the need to use specialized tools\n * Mounting to the ceiling or on the wall',
    'L40_description': '* Smooth and silent ball bearing rollers\n * Track length from 100 to 200 cm\n * Max. door weight 40 kG\n * Easy assembly without the need to use specialized tools\n * Mounting to the ceiling or on the wall',

    'W40_description': '* Smooth and silent ball bearing rollers\n * Track length from 100 to 200 cm\n * Max. door weight 45 kg\n * Easy assembly without the need to use specialized tools\n * Low track height\n * Mounting to the ceiling\n * Soft close available',
    'W40_kit_description': '* Smooth and silent ball bearing rollers\n * Max. door weight 45 kg\n * Easy assembly without the need to use specialized tools',
    'W40_good_to_know': '* Door material: Wood, plywood, chipboard, MDF and honeycomb panel\n * Door thickness 16–40 mm\n * System for 2 door leaves: Track 100—180 cm\n * System for 3 door leaves: Track 181—240 cm\n * Doors not included in delivery',
    'W40_kit_good_to_know': '* Door material: Wood, plywood, chipboard, MDF and honeycomb panel\n * Door thickness 16–40 mm\n * Doors not included in delivery',
    
    'W60_description': '* Sliding door system for wardrobes\n * Track length from 100 to 200 cm\n * Smooth and silent ball bearing rollers\n * Easy assembly without the need to use specialized tools\n * Very low track height – difference between door leaf height and clear height is only 8 mm\n * Max. door weight 70 kg',
    'W60_kit_description': '* Sliding door system for wardrobes\n * Smooth and silent ball bearing rollers\n * Easy assembly without the need to use specialized tools\n * Max. door weight 70 kg',
    'W60_good_to_know': '* Door material: Wood, plywood, chipboard, MDF and honeycomb panel\n * Door thickness from 16 mm\n * Tracks can be moved apart and the door of any thickness can be used\n * System for 2 door leaves: Track 100—180 cm\n * System for 3 door leaves: Track 181—240 cm\n * Doors not included in delivery',
    'W60_kit_good_to_know': '* Door material: Wood, plywood, chipboard, MDF and honeycomb panel\n * Door thickness from 16 mm\n * Doors not included in delivery',

    'FSC_925_description': '* For sliding door systems S60, S100, S120, G100\n * Assembly to the wall mounted rail\n * Max. door thickness 25 mm\n * Height: 10 cm\n * With dust brush',
    'FSC_925_good_to_know': '* NOTE: Length in the configurator indicates length of the rail (fascia will be 1 cm longer)',
    'FSC_945_description': '* For sliding door systems S60, S100, S120\n* Assembly to the wall mounted rail\n * Max. door thickness 45 mm\n * Height: 10 cm\n * With dust brush',
    'FSC_945_good_to_know': '* NOTE: Length in the configurator indicates length of the rail (fascia will be 1 cm longer)',
    'FSC_980_description': '* For sliding door systems S60, S100, S120, G100\n * Can be mounted on a wall-mounted or ceiling-mounted rail',
    'FSC_980_good_to_know': '* When assembled to the wall mounted rail with wall brackets, the minimum distance between the rail\'s upper surface and the ceiling is 15 mm\n * When assembled to the ceiling mounted rail, distance of 15 mm must be provided between the ceiling and the upper surface of the rail. The required distance is best obtained by using available spacers',

    'FSC_937_description': '* For sliding door systems S60, S100, S120, G100\n * Assembly to the wall mounted rail\n * Max. door thickness 25 mm\n * Height: 10 cm\n * With dust brush',
    'FSC_937_good_to_know': '* NOTE: Length in the configurator indicates length of the rail (fascia will be 1 cm longer)',
    'FSC_957_description': '* For sliding door systems S60, S100, S120\n* Assembly to the wall mounted rail\n * Max. door thickness 45 mm\n * Height: 10 cm\n * With dust brush',
    'FSC_957_good_to_know': '* NOTE: Length in the configurator indicates length of the rail (fascia will be 1 cm longer)',
    'FSC_992_description': '* For sliding door systems S60, S100, S120, G100\n * Can be mounted on a wall-mounted or ceiling-mounted rail',
    'FSC_992_good_to_know': '* When assembled to the wall mounted rail with wall brackets, the minimum distance between the rail\'s upper surface and the ceiling is 15 mm\n * When assembled to the ceiling mounted rail, distance of 15 mm must be provided between the ceiling and the upper surface of the rail. The required distance is best obtained by using available spacers',

    'SPCR_description': '* Provides a distance between the rail and the ceiling, necessary for the assembly of a flat aluminum fascia\n * For ceiling mounted rail\n * For sliding door systems S60, S100, S120, G100',

    'F25_description': '* Max. door weight 25 kg (both panels)\n * Track length from 80 to 200 cm\n * Ceiling mounting',
    'F40_description': '* Max. door weight 40 kg (both panels)\n * Track length from 80 to 200 cm\n * Ceiling mounting',
    'F25_good_to_know': `${F25_table_1} ${F25_table_2} * Options available in the configurator: wooden fascia brackets\n * Doors not included in delivery`,
    'F40_good_to_know': `${F40_table_1} ${F40_table_2} * Options available in the configurator: wooden fascia brackets\n * Doors not included in delivery`,
    'F25_kit_description': '* Max. door weight 25 kg (both panels)\n * Kit for 2 additional panels',
    'F40_kit_description': '* Max. door weight 40 kg (both panels)\n * Kit for 2 additional panels',
    'RO-F25_description': '* Max. weight 25 kg',
    'RO-F40_description': '* Max. weight 40 kg',

    'WFB-SG_description': '* Enables installation of a fascia made of wood (plywood, chipboard, MDF, honeycomb panel or similar)\n * For sliding door systems S60, S100, S120, G80, G100',
    'WFB-F_description': '* Enables installation of a fascia made of wood (plywood, chipboard, MDF, honeycomb panel or similar)\n * For folding door systems F25, F40',
    'CB-25_description': '* Connects two tracks mounted on the wall\n * For sliding door systems S60, S100, S120, G80, G100\n * Max. door thickness 25 mm',
    'CB-45_description': '* Connects two tracks mounted on the wall\n * For sliding door systems S60, S100, S120\n * Max. door thickness 45 mm',
    'WB-25_description': '* Wall bracket for fixing the track to the wall\n * For sliding door systems S60, S100, S120, G80, G100\n * Max. door thickness 25 mm',
    'WB-45_description': '* Wall bracket for fixing the track to the wall\n * For sliding door systems S60, S100, S120\n * Max. door thickness 45 mm',

    'LFT-HND-01_description': '* For wooden doors\n * Height 20 cm\n * 1 pcs.',
    'LFT-HND-02_description': '* For wooden doors\n * Height 32 cm\n * 1 pcs.',
    'LFT-HND-03_description': '* For wooden doors\n * Height 28 cm\n * 1 pcs.',
    'GHR-058_description': '* For glass sliding doors\n * Glass thickness from 8 to 12 mm\n * Finish: satin steel',
    'GHR-058-BM_description': '* For glass sliding doors\n * Glass thickness from 8 to 12 mm\n * Finish: matte black',
    'TRC_F_description': '* For folding door systems F25, F40',
    'TRC_SG_description': '* For sliding door systems S60, S100, S120, G80, G100',
    'LG-01_description': 'Made of transparent plastic',
    'LG-02_description': 'Made of transparent plastic',

    'PURA_description': '* Discover modernity and elegance with our sliding door system with a completely hidden track. Designed to fit perfectly into minimalist interiors, it offers smooth and quiet operation.\n * Innovative design that makes the guide invisible, ensuring a clean and aesthetic appearance.\n * Thanks to advanced technology, the door slides almost silently.\n * Made of high-quality materials, guarantees durability and reliability.',
    'PURA_good_to_know': '* The system is intended for wooden sliding doors\n * Maximum door weight 60 kg\n * Door width 80-120 cm\n * Door thickness 28-45 mm\n * Wall mounting\n * The set does not include doors',
    'PURA_bullet_1': 'Maximum door weight 80 kg',
    'PURA_bullet_2': 'Door leaf width 80-120 cm',
    'PURA_bullet_3': 'Wall mounted',

    //Opisy Rysunkow
    'Rys.': 'Fig.',
    'ceiling_mounted': 'Ceiling surface mounted',
    'wall_mounted': 'Wall mounted with optional wall fixing brackets',
    'wall_mounted_25': 'Wall mounted with optional wall fixing brackets 25',
    'wall_mounted_45': 'Wall mounted with optional wall fixing brackets 45',
    'wall_mounted_25_wooden_fascia': 'Wall mounted with optional wall fixing brackets 25 and wooden fascia',
    'wall_mounted_45_wooden_fascia': 'Wall mounted with optional wall fixing brackets 45 and wooden fascia',
    'wall_mounted_25_aluminium_fascia': 'Wall mounted with optional wall fixing brackets 25 and aluminium fascia 25',
    'wall_mounted_45_aluminium_fascia': 'Wall mounted with optional wall fixing brackets 45 and aluminium fascia 45',


    //Super krotki opis
    'Drzwi do 25 kg': 'Doors up to 25 kg',
    'Drzwi do 40 kg': 'Doors up to 40 kg',
    'Drzwi do 45 kg': 'Doors up to 45 kg',
    'Drzwi do 60 kg': 'Doors up to 60 kg',
    'Drzwi do 70 kg': 'Doors up to 70 kg',
    'Drzwi do 80 kg': 'Doors up to 80 kg',
    'Drzwi do 100 kg': 'Doors up to 100 kg',
    'Drzwi do 120 kg': 'Doors up to 120 kg',
    'Prowadnica 80–200 cm': 'Track 80–200 cm',
    'Prowadnica 100–300 cm': 'Track 100–200 cm',
    'Prowadnica 160–300 cm': 'Track 160–200 cm',
    'Prowadnica 200 cm': 'Track 200 cm',
    'Montaż do stropu lub ściany': 'Ceiling or wall mounting',
    'Synchroniczne otwieranie 2 drzwi': 'Synchronous opening of 2 doors',
    'Do S60, S100, S120': 'For S60, S100, S120',
    'Łagodnie domyka drzwi': 'Closes the door smoothly',
    'Niewidoczny montaż': 'Invisible assembly',
    'Do drzwi przesuwnych szklanych': 'For glass sliding doors',
    'Grubość szkła 8–12 mm': 'Glass thickness 8–12 mm',
    'Montaż do ściany': 'Wall mounting',
    'Montaż do stropu': 'Ceiling mounting',
    'Niski tor jezdny': 'Low track height',

    'Mocowana na szynę montowaną naściennie': 'Assembly to the wall mounted rail',
    'Do systemów F25, F40': 'For systems F25, F40',
    'Do systemów S60, S100, S120, G80, G100': 'For systems S60, S100, S120, G80, G100',
    'Do systemów G80, G100': 'For systems G80, G100',
    'Do systemów S60, S100, S120': 'For systems S60, S100, S120',
    'Do systemów S60, S100, S120, G100': 'For systems S60, S100, S120, G100',
    'Grubość drzwi do 25 mm': 'Max. door thickness 25 mm',
    'Grubość drzwi do 45 mm': 'Max. door thickness 45 mm',
    'Na dodatkowe skrzydło drzwi': 'For additional door leaf',
    'Komplet 2 szt.': 'Set of 2 pcs.',
    'Do drzwi drewnianych': 'For wooden doors',
    'Wysokość 19.5 cm': 'Height 19.5 cm',
    'Wysokość 28 cm': 'Height 28 cm',
    'Wysokość 32 cm': 'Height 32 cm',
    '1 szt.': '1 piece',
    'Obciążenie do 60 kg (na 2 wózkach)': 'Max. 60 kg (with 2 rollers)',
    'Obciążenie do 100 kg (na 2 wózkach)': 'Max. 100 kg (with 2 rollers)',
    'Obciążenie do 120 kg (na 2 wózkach)': 'Max. 120 kg (with 2 rollers)',
    'Obciążenie do 25 kg': 'Max. 25 kg',
    'Obciążenie do 40 kg': 'Max. 40 kg',
    'Z przezroczystego tworzywa': 'Made of transparent plastic',
    'Do prowadnicy mocowanej do sufitu': 'For ceiling mounted track',
    'Łączy dwie prowadnice mocowane na ścianie': 'Connects two tracks mounted on the wall',
    'Umożliwia montaż prowadnicy na ścianie': 'For fixing the track to the wall',
    'Umożliwia montaż maskownicy drewnianej': 'For mounting a wooden panel',

    //Manuals
    'PURA_manual': 'Pura system assembly manual',
    'L30_manual': 'L30 system assembly manual',
    'L40_manual': 'L40 system assembly manual',
    'W60_manual': 'W60 system assembly manual',
    'W40_manual_2': 'W40 system assembly manual (two-door closet)',
    'W40_manual_3': 'W40 system assembly manual (three-door closet)',
    'DS40_manual': 'Soft close damper assembly manual',
    
    'F25_manual': 'F25 system assembly manual',
    'F40_manual': 'F40 system assembly manual',
    'S60_manual': 'S60 system assembly manual',
    'S100_manual': 'S100 system assembly manual',
    'S120_manual': 'S120 system assembly manual',
    'G80_manual': 'G80 system assembly manual',
    'G100_manual': 'G100 system assembly manual',
    'WB45_manual': 'Wall fixing bracket 45 assembly manual',
    'WB25_manual': 'Wall fixing bracket 25 assembly manual',
    'WFBSG_manual': 'Wooden fascia bracket assembly manual',
    'WFBF_manual': 'Wooden fascia bracket assembly manual',
    'SCD-S_manual': 'Soft close damper assembly manual',
    'SCD-SG_manual': 'Soft close damper assembly manual',
    '2S_manual': '2S Synchronization kit assembly manual',
    'FSC925_manual': 'Anodized aluminium fascia with end caps 25 assembly manual',
    'FSC945_manual': 'Anodized aluminium fascia with end caps 45 assembly manual',
    'FSC980_manual': 'Flat anodized aluminium fascia assembly manual',

    //Checkout
    'Creating system': 'Your personalized product is created in a database.',
    'Redirecting': 'You will be redirected to the checkout in a moment.',
    'Please be patient': 'It will take short.',


}